import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TABLET } from '../../constants/breakpoints'
import { DARKEST, PRIMARY_THIRD } from '../../constants/colors'

const Container = styled.div`
  margin: 0;
  padding: 2rem;
  padding-bottom: 0;
  border-radius: 10px 10px 0 0;

  @media screen and (max-width: ${TABLET}px) {
    padding: 0;
  }
`

const BottomText = styled.h1`
  padding-bottom: 0.25rem;
  color: ${DARKEST}ee;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  text-align: right;
  text-transform: uppercase;

  @media screen and (max-width: ${TABLET}px) {
    margin: 0;
  }
`

const TopText = styled.h2`
  text-transform: uppercase;
  color: ${PRIMARY_THIRD};
  font-size: 1rem !important;
  padding-bottom: 0.25rem;
  margin-bottom: 0;
  text-align: right;

  @media screen and (max-width: ${TABLET}px) {
    margin: 0;
    font-size: 1rem !important;
    border: none;
  }
`

const Component = ({ top, bottom }) => {
  if (!top && !bottom) return <React.Fragment />
  return (
    <Container>
      {top && <TopText>{top}</TopText>}
      {bottom && <BottomText>{bottom}</BottomText>}
    </Container>
  )
}

Component.defaultProps = {
  bottom: '',
  top: '',
}

Component.propTypes = {
  bottom: PropTypes.node,
  top: PropTypes.node,
}

export default Component
