import React from 'react'
import styled from 'styled-components'
import Background from '../../components/Background'
import Layout from '../../components/Layout'
import LeadForm from '../../components/Form/lead'
import { TABLET } from '../../constants/breakpoints'
import { LIGHTEST, PRIMARY_THIRD } from '../../constants/colors'
import { METADATA } from '../../config/callMe'
import { POSTAL_URL_BASE } from '../../../env'

const Wrapper = styled.div``

const LargeContainer = styled.div`
  display: block;
  text-align: right;

  @media screen and (max-width: ${TABLET}px) {
    display: none;
  }
`

const SmallContainer = styled.div`
  display: none;

  @media screen and (max-width: ${TABLET}px) {
    display: block;
    overflow: hidden;
  }
`

const Page = () => (
  <Layout {...METADATA}>
    <Wrapper>
      <LargeContainer>
        <Background
          filename="hero-welder-02.jpg"
          size="100%"
          width="100%"
          color={PRIMARY_THIRD}
        >
          <LeadForm
            postalUrl={POSTAL_URL_BASE}
            type="hero"
            heading={{
              top: 'Jot down your details below and',
              bottom: "we'll give you a call to get you started",
            }}
          />
        </Background>
      </LargeContainer>
      <SmallContainer>
        <Background filename="pattern.png" size="cover" color={LIGHTEST}>
          <LeadForm
            postalUrl={POSTAL_URL_BASE}
            type="hero"
            heading={{
              top: 'Fill in your details',
              bottom: 'So that we can give you a call',
            }}
          />
        </Background>
      </SmallContainer>
    </Wrapper>
  </Layout>
)

export default Page
