import { RML } from '@simply-fin-services/astronomix'

export const DESKTOP = RML.ui.containerWidth

export const TWO_THIRDS_OF_DESKTOP = (DESKTOP / 3) * 2

export const TABLET = 1024

export const PHONE = 768

export const ABOVE_THE_FOLD_MAX_HEIGHT = 768

export const MAX_HEIGHT_TO_PULL_FEATURES_CLOSER_TO_QQ_FROM = 900

export default {
  DESKTOP,
  TABLET,
  PHONE,
  TWO_THIRDS_OF_DESKTOP,
  ABOVE_THE_FOLD_MAX_HEIGHT,
}
