import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { LIGHTEST, NEUTRAL } from '../../constants/colors'

/* ----------------- Astro overrides :( ----------------- */
const AstroOverrides = styled.div`
  /* Form */
  > div {
    background-color: transparent;
    width: 100%;
    max-width: 100%;
    padding: ${(props) => (props.padForm ? '1.5rem 1.5rem 2rem 1.5rem' : '0')};
  }

  /* Anything resembling a label */
  label,
  span {
    font-weight: normal !important;
    white-space: ${(props) => (props.wrapLabels ? 'wrap' : 'nowrap')};
  }

  /* Placeholders */
  input::placeholder,
  div[class$='placeholder'] {
    font-weight: 100;
    color: #aaa;
  }

  /* Quick Quote button options (male, female and evenly split) */
  button[data-color='neutral'] {
    background-color: ${LIGHTEST};
    font-weight: normal;
    border: solid 1px ${NEUTRAL};
    color: #aaa;
    display: inline-block;
    margin: ${(props) => (props.pad ? '0.25rem 0' : '0')};
  }

  /* Submit button */
  button[disabled],
  button[disabled]:hover {
    color: #505050;
    background-color: #c9c9c9;
    border: solid 1px #a9a9a9;
  }
`

const AstroForm = ({ pad, wrapLabels, children }) => (
  <AstroOverrides padForm={pad} wrapLabels={wrapLabels}>
    {children}
  </AstroOverrides>
)

AstroForm.defaultProps = {
  pad: true,
  wrapLabels: false,
}

AstroForm.propTypes = {
  children: PropTypes.node.isRequired,
  pad: PropTypes.bool,
  wrapLabels: PropTypes.bool,
}

export default AstroForm
