import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Container = styled.div`
  background-color: ${(props) => props.color || 'transparent'};
`

const Overlay = styled.div`
  background-image: url('${(props) => props.path}');
  background-attachment: ${(props) => props.attachment || 'scroll'};
  background-size: ${(props) => props.size || 'auto'};
  background-repeat: ${(props) => props.repeat || 'no-repeat'};
  background-position: ${(props) => props.position || 'top'};
  filter: ${(props) => props.filter || 'none'};
  opacity: ${(props) => props.opacity || 1};
  width: ${(props) => props.width || 'auto'};
`

const Children = styled.div`
  position: relative;
`

const Background = ({
  filename,
  color,
  attachment,
  size,
  repeat,
  position,
  filter,
  width,
  children,
}) => {
  const path = require(`../../images/${filename}`)
  return (
    <Container className="background-image" color={color}>
      <Overlay
        className="background-image-overlay"
        path={path}
        backgroundColor={color}
        attachment={attachment}
        size={size}
        repeat={repeat}
        position={position}
        filter={filter}
        width={width}
      >
        <Children>{children}</Children>
      </Overlay>
    </Container>
  )
}

Background.defaultProps = {
  attachment: '',
  children: <div></div>,
  color: 'transparent',
  filter: '',
  repeat: '',
  position: '',
  size: 'cover',
  width: '100%',
}

Background.propTypes = {
  attachment: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  filename: PropTypes.string.isRequired,
  filter: PropTypes.string,
  position: PropTypes.string,
  repeat: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
}

export default Background
