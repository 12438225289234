import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Heading from '../Heading'
import AstroForm from './astro'
import { LeadformGroup } from '@simply-fin-services/astronomix'
import { trackEvent } from '../../utils/tracking'
import { BORDER_RADIUS, getStyleByType } from './index'
import { LIGHTEST, NEUTRAL } from '../../constants/colors'
import { DESKTOP, PHONE, TABLET } from '../../constants/breakpoints'

/* A container strip from far left to far right */
const Strip = styled.div`
  max-width: ${(props) =>
    getStyleByType(props.type, {
      hero: `${DESKTOP}px`,
      inline: '100%',
      def: 'auto',
    })};

  padding-top: ${(props) =>
    getStyleByType(props.type, {
      hero: '6rem',
      inline: props.visible ? '1.5rem' : '0',
      def: '0',
    })};

  margin: 0 auto;

  @media screen and (min-width: ${TABLET}px) and (max-width: ${DESKTOP}px) {
    /* Align form with the header. */
    padding-right: ${(props) =>
      getStyleByType(props.type, {
        hero: '2rem',
        inline: '0',
        def: '0',
      })};
  }

  @media screen and (max-width: ${TABLET}px) {
    padding-top: ${(props) =>
      getStyleByType(props.type, {
        hero: '1.5rem',
        inline: props.visible ? '1.5rem' : '0',
        def: '1.5rem',
      })};

    padding-bottom: ${(props) =>
      getStyleByType(props.type, {
        hero: '1.5rem',
        inline: '1.5rem',
        def: '1.5rem',
      })};
  }
`

const Container = styled.div`
  max-width: 500px;
  margin: 0 auto;
  margin-right: 0;
  border-radius: ${BORDER_RADIUS}px;

  background-color: ${LIGHTEST}66;

  padding: ${(props) =>
    getStyleByType(props.type, {
      hero: `1rem`,
      inline: '0',
      def: '0',
    })};

  margin-top: ${(props) =>
    getStyleByType(props.type, {
      hero: `0`,
      inline: '1rem',
      def: '0',
    })};

  @media screen and (max-width: ${TABLET}px) {
    background-color: transparent;
    margin: 0 auto;
    width: 100%;

    margin-top: ${(props) =>
      getStyleByType(props.type, {
        hero: `0`,
        inline: '0',
        def: '0',
      })};
  }
`

const Wrapper = styled.div`
  border-radius: ${BORDER_RADIUS}px;
  box-shadow: ${(props) =>
    getStyleByType(props.type, {
      hero: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;`,
      inline: `2px 2px 10px ${NEUTRAL}`,
      def: `2px 2px 10px ${NEUTRAL}`,
    })};

  background-color: ${(props) =>
    getStyleByType(props.type, {
      hero: `${LIGHTEST}cc`,
      inline: 'transparent',
      def: 'transparent',
    })};

  @media screen and (min-width: ${PHONE}px) and (max-width: ${TABLET}px) {
    background-color: ${LIGHTEST}66;
  }

  @media screen and (max-width: ${PHONE}px) {
    background-color: transparent;
    box-shadow: none;
  }
`

const HeadingContainer = styled.div`
  padding-top: ${(props) => (props.visible ? '1rem' : '2rem')};

  display: ${(props) =>
    getStyleByType(props.type, {
      hero: props.visible ? 'block' : 'none',
      inline: 'none',
      def: 'none',
    })};

  @media screen and (max-width: ${TABLET}px) {
    display: ${(props) => (props.visible ? 'block' : 'none')};
    margin-top: ${(props) => (props.visible ? '1rem' : '0')};

    h1,
    h2 {
      text-align: center;
    }
  }
`

const hasHeading = (heading) => heading.top || heading.bottom

const LeadForm = ({ postalUrl, heading, type }) => (
  <Strip type={type} visible={hasHeading(heading)}>
    <Container type={type}>
      <Wrapper type={type}>
        <HeadingContainer type={type} visible={hasHeading(heading)}>
          <Heading top={heading.top} bottom={heading.bottom} />
        </HeadingContainer>
        <AstroForm>
          <LeadformGroup
            track={() => trackEvent('GroupCallMe')}
            endpoint={`${postalUrl}/postal-api/hotlead`}
            isEmailRequired={false}
          />
        </AstroForm>
      </Wrapper>
    </Container>
  </Strip>
)

LeadForm.defaultProps = {
  heading: {},
}

LeadForm.propTypes = {
  heading: PropTypes.shape({
    bottom: PropTypes.string,
    top: PropTypes.string,
  }),
  postalUrl: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['hero', 'inline']).isRequired,
}

export default LeadForm
