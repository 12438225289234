export const METADATA = {
  route: '/call-me',
  imagePath: 'social-call-me.jpg',
  pageType: 'website',
  title: 'Let us call you',
  description: `
    Would you like a quote? Let us call you.
    Or you can email us at groupadmin@randmutual.co.za
    or call us on 021 045 1448.
  `,
}
